/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { LoggerService, ParamSerializer } from './shared';
import type { ResolveFn } from '@angular/router';
import { AuthService } from './auth.service';
import type { AuiRoute, AuiRoutes } from '@angeltrax/ngx-aui/lib/shared';
import type { HttpErrorResponse } from '@angular/common/http';

// finds the authorized route with the highest priority.
const findHighestPrioRoute = (routes: AuiRoutes, perms: string[]): AuiRoute | null => {
  const sortedRoutes = routes?.filter(x => (x.data?.priority ?? 0) > 0)
    .sort((a: AuiRoute, b: AuiRoute) => (a.data!.priority ?? 100) - (b.data!.priority ?? 100));
  for (const route of sortedRoutes) {
    if (!route.data?.permissions) {
      return route;
    }

    // handles avl_* type permissions
    const anyPerm = (route.data?.permissions?.oneOf ?? []).find(x => x.includes('_*')) ??
    (route.data?.permissions?.allOf ?? []).find(x => x.includes('_*'));
    if (anyPerm) {
      const prefix = anyPerm.substring(0, anyPerm.indexOf('*'));

      if (perms.some(x => x.startsWith(prefix))) {
        return route;
      }
    }

    // checks user permissions against permissions defined on route
    const hasRoutePermission = perms.some(x => route.data?.permissions?.oneOf?.includes(x)) ??
    perms.some(x => route.data?.permissions?.allOf?.includes(x)) ?? '';
    if (hasRoutePermission) {
      return route;
    }
  }

  return null;
};


export const ModuleRedirectResolver: ResolveFn<void> =
 async(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> => {
   const router = inject(Router);
   const paramSerializer = inject(ParamSerializer);
   const logger = inject(LoggerService);
   const authService = inject(AuthService);
   const filteredRoutes: AuiRoutes = (route.parent?.routeConfig?.children as AuiRoutes | undefined) ?? [];

   const isLoggedIn = await authService.isLoggedIn();

   if (isLoggedIn) {
     authService.getCurrentOrganization().pipe(first())
       .subscribe({
         next: (res) => {
           let prioRoute = findHighestPrioRoute(filteredRoutes, res.permissions);
           let highestPriorityAuthorizedRoutePath = prioRoute?.path ?? '';
           while (prioRoute?.children && prioRoute.children.length > 0) {
             prioRoute = findHighestPrioRoute(prioRoute.children, res.permissions);
             highestPriorityAuthorizedRoutePath = highestPriorityAuthorizedRoutePath.concat(`/${prioRoute?.path ?? ''}`);
           }

           if (prioRoute?.data?.queryParams) {
             const params = paramSerializer.serialize(prioRoute.data?.queryParams as Record<string, any>);
             highestPriorityAuthorizedRoutePath = highestPriorityAuthorizedRoutePath.concat(`?${params}`);
           }


           if (highestPriorityAuthorizedRoutePath) {
             // navigate to path of highest priority authorized (? split knocks off any query string that could compromse state url)
             router.navigateByUrl(`${state.url.split('?')[0]}/${highestPriorityAuthorizedRoutePath}`)
               .catch((err: Error) => {
                 logger.logError(err);
               });
           } else if (state.url === '/') {
             // if no (top level) module path found, direct to no modules error page
             router.navigateByUrl('error-pages/no-modules')
               .catch((err: Error) => {
                 logger.logError(err);
               });
           } else {
           // if no submodules path found, direct back to top level
             router.navigateByUrl('')
               .catch((err: Error) => {
                 logger.logError(err);
               });
           }
         },
         error: (err: HttpErrorResponse) => {
           if (err.status === 403) {
             router.navigateByUrl('error-pages/no-organizations')
               .catch((err2: Error) => {
                 logger.logError(err2);
               });
           } else {
             router.navigateByUrl('error-pages/error')
               .catch((err2: Error) => {
                 logger.logError(err2);
               });
           }
         },
       });
   } else {
     await router.navigateByUrl('login');
   }
 };

