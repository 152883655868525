export class PermissionList {

  public static readonly ADMIN_MANAGE_API_KEYS = 'admin_manage_api_keys';

  public static readonly ADMIN_MANAGE_AVL_SETTINGS = 'admin_manage_avl_settings';

  public static readonly ADMIN_MANAGE_DRIVER_BEHAVIOR_SETTINGS = 'admin_manage_driver_behavior_settings';

  public static readonly ADMIN_MANAGE_DRIVERS = 'admin_manage_drivers';

  public static readonly ADMIN_MANAGE_GROUPS = 'admin_manage_groups';

  public static readonly ADMIN_MANAGE_ORG_SETTINGS = 'admin_manage_org_settings';

  public static readonly ADMIN_MANAGE_ROLES = 'admin_manage_roles';

  public static readonly ADMIN_MANAGE_STUDENTS = 'admin_manage_students';

  public static readonly ADMIN_MANAGE_TRANSIT_SETTINGS = 'admin_manage_transit_settings';

  public static readonly ADMIN_MANAGE_USERS = 'admin_manage_users';

  public static readonly ADMIN_MANAGE_VEHICLES = 'admin_manage_vehicles';

  public static readonly ADMIN_VIEW_AUDIT_LOGS = 'admin_view_audit_logs';

  public static readonly AVL_MANAGE_FIXED_ROUTES = 'avl_manage_fixed_routes';

  public static readonly AVL_MANAGE_GEOFENCES = 'avl_manage_geofences';

  public static readonly AVL_MANAGE_OVERLAYS = 'avl_manage_overlays';

  public static readonly AVL_VIEW_EVENTS = 'avl_view_events';

  public static readonly AVL_VIEW_FLEET_REPORT = 'avl_view_fleet_report';

  public static readonly AVL_VIEW_GEOFENCE_COVERAGE_REPORT = 'avl_view_geofence_coverage_report';

  public static readonly AVL_VIEW_GEOFENCE_EVENT_REPORT = 'avl_view_geofence_event_report';

  public static readonly AVL_VIEW_GEOFENCE_MILEAGE_REPORT = 'avl_view_geofence_mileage_report';

  public static readonly AVL_VIEW_LIVE_TRACKING = 'avl_view_live_tracking';

  public static readonly AVL_VIEW_ROUTE_REWIND = 'avl_view_route_rewind';

  public static readonly DA_MANAGE_DEFAULT_DRIVERS = 'da_manage_default_drivers';

  public static readonly DA_MANAGE_DRIVE_SESSION_ISSUES = 'da_manage_drive_session_issues';

  public static readonly DA_MANAGE_SPEED_OVERRIDE_ZONES = 'da_manage_speed_override_zones';

  public static readonly DA_VIEW_ALERTS = 'da_view_alerts';

  public static readonly DA_VIEW_CHECK_INS = 'da_view_check_ins';

  public static readonly DA_VIEW_COACHING_NOTICES = 'da_view_coaching_notices';

  public static readonly DA_VIEW_DEFAULT_DRIVERS = 'da_view_default_drivers';

  public static readonly DA_VIEW_DRIVE_SESSIONS = 'da_view_drive_sessions';

  public static readonly DA_VIEW_SCORE_DETAILS = 'da_view_score_details';

  public static readonly DA_VIEW_SCORES = 'da_view_scores';

  public static readonly DA_VIEW_TRENDS = 'da_view_trends';

  public static readonly HEALTH_MANAGE_UPDATE_TASKS = 'health_manage_update_tasks';

  public static readonly LV_WATCH = 'lv_watch';

  public static readonly SAV_APPROVE_VIOLATIONS = 'sav_approve_violations';

  public static readonly SAV_CANCEL_COURT_HEARING = 'sav_cancel_court_hearing';

  public static readonly SAV_MANAGE_ADDITIONAL_DOCUMENTS = 'sav_manage_additional_documents';

  public static readonly SAV_MANAGE_CLOSED_REASONS = 'sav_manage_closed_reasons';

  public static readonly SAV_MANAGE_COURT_HEARINGS = 'sav_manage_court_hearings';

  public static readonly SAV_REQUEST_COURT_HEARING = 'sav_request_court_hearing';

  public static readonly SAV_REQUEST_MORE_VIDEO = 'sav_request_more_video';

  public static readonly SAV_RESCHEDULE_COURT_HEARING = 'sav_reschedule_court_hearing';

  public static readonly SAV_SEARCH_COURT_DOCKETS = 'sav_search_court_dockets';

  public static readonly SAV_TRANSFER_LIABILITY = 'sav_transfer_liability';

  public static readonly SAV_UPDATE_COURT_STATUS = 'sav_update_court_status';

  public static readonly SAV_UPDATE_PAYMENT_PLAN = 'sav_update_payment_plan';

  public static readonly SAV_UPDATE_UTC_STATUS = 'sav_update_utc_status';

  public static readonly SAV_VIEW_FORMS = 'sav_view_forms';

  public static readonly SAV_VIEW_PAYMENT_REPORTS = 'sav_view_payment_reports';

  public static readonly SAV_VIEW_SIGNATURES = 'sav_view_signatures';

  public static readonly SAV_VIEW_VIOLATION_REPORTS = 'sav_view_violation_reports';

  public static readonly SAV_VIEW_VIOLATIONS = 'sav_view_violations';

  public static readonly SAV_VIEW_VIOLATIONS_MAP = 'sav_view_violations_map';

  public static readonly SAV_VIEW_UTC_BATCHES = 'sav_view_utc_batches';

  public static readonly ST_VIEW_CHECK_INS = 'st_view_check_ins';

  public static readonly TRANSIT_UPLOAD_GTFS_FEED = 'transit_upload_gtfs_feed';

  public static readonly TRANSIT_VIEW_PC_ALL = 'transit_view_pc_all';

  public static readonly VM_MANAGE_MANUAL_TASKS = 'vm_manage_manual_tasks';

  public static readonly VM_PLAYBACK_MANUAL_TASKS = 'vm_playback_manual_tasks';

}
