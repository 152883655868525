import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { type Subscription, from } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { LoggerService } from 'src/app/shared';
import type { AlertMessage } from 'src/app/shared';
import { UserPreferencesService } from '../user-preferences/shared/user-preferences.service';
import { take } from 'rxjs/operators';
import type { OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import type { HttpErrorResponse } from '@angular/common/http';

/**
 * Component for displaying a 404 page
 */
@Component({
  selector: 'mtx-user-join-org',
  templateUrl: './user-join-org.component.html',
  styleUrls: ['./user-join-org.component.scss'],
})
export class UserJoinOrgComponent implements OnInit {

  /** Token for org */
  public token?: string;

  public currentUserName?: { first_name: string; last_name: string };

  /** Displays error/success messages */
  public messages: AlertMessage[] = [];

  public isReady = false;

  /** Subscriptions for getting url params */
  private routeParamSub?: Subscription;

  /** Change page message */
  private accepted = false;

  public constructor(@Inject(ActivatedRoute) private readonly activatedRoute: ActivatedRoute,
    @Inject(LoggerService) private readonly loggerService: LoggerService,
    @Inject(OAuthService) private readonly oauthService: OAuthService,
    @Inject(UserPreferencesService) private readonly userPreferencesService: UserPreferencesService,
    @Inject(ChangeDetectorRef) private readonly cdRef: ChangeDetectorRef,
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(Router) private readonly router: Router) { }

  /** Translates page */
  public ngOnInit(): void {
    this.routeParamSub = this.activatedRoute.params.subscribe({
      next: (param) => {
        if (param.token) {
          this.token = param.token;

          from(this.authService
            .isLoggedIn())
            .pipe(take(1))
            .subscribe({
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              next: async(isLoggedIn) => {
                if (isLoggedIn) {
                  this.currentUserName = this.authService.getUserInfo();
                  this.isReady = true;
                } else {
                  await this.authService.login(this.router.url);
                }
              },
            });
        }
      },
    });

    this.cdRef.detectChanges();
  }

  /** Adds user to org */
  public acceptInvite(): void {
    this.messages = [];

    if (!this.token) {
      this.messages.push({
        type: 'error',
        msg: 'Invalid token not set.',
      });
      return;
    }

    this.userPreferencesService.addUserToOrg(this.token).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      next: async() => {
        this.accepted = true;
        this.authService.clearCache();
        await this.authService.reloadCurrentUser();
        await this.router.navigate(['/']);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status !== -1) {
          this.messages = this.loggerService.apiErrorsToDisplayErrors(error);
        }
      },
    });
  }

  public acceptInviteNotLoggedIn(): void {
    this.messages = [];

    this.oauthService.logOut();
  }

}
