<div class="push"></div>
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 text-center">
                <img id="footer-logo" src="/assets/images/logo.png" class="footer-img" />

                <ul id="footer-nav" class="list-inline d-none d-lg-inline-block">
                    <li class="list-inline-item">
                        <a href="https://www.angeltrax.com/privacy-policy" target="_blank" [translate]="'general.privacy-policy'"></a>
                    </li>
                    <li class="list-inline-item">|</li>
                    <li class="list-inline-item">
                        <a href="https://www.angeltrax.com/terms-of-service" target="_blank" [translate]="'general.toa'"></a>
                    </li>
                    <li class="list-inline-item">|</li>

                    <li class="list-inline-item">
                        <a href="https://secure.logmeinrescue.com/Customer/Code.aspx" target="_blank" [translate]="'general.logmein'"></a>
                    </li>
                    <li class="list-inline-item">|</li>
                    <li class="list-inline-item">
                        <a href="tel:18006731788">1.800.673.1788</a>
                    </li>
                    <li class="list-inline-item">
                        <a id="appVersion">{{appVersion}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
